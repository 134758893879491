import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils';

const Drawer: ComponentStyleConfig = {
  baseStyle({ theme }) {
    return {
      header: {
        ...getTypography('titleLarge', theme),
        borderBottom: '1px',
        borderColor: 'grey.shade',
      },
      dialog: {
        maxH: 'var(--chakra-vh)',
      },
      footer: {
        borderTop: '1px',
        borderColor: 'grey.shade',
      },
    };
  },
  sizes: {
    sm: {
      dialog: {
        maxW: '424px',
      },
    },
    '480px': {
      dialog: {
        maxW: '480px',
      },
    },
    '545px': {
      dialog: {
        maxW: '545px',
      },
    },
    '600px': {
      dialog: {
        maxW: '600px',
      },
    },
    chat: {
      dialog: {
        maxW: '760px',
      },
    },
  },
};

export default Drawer;
