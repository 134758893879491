import { ComponentStyleConfig, ThemingProps } from '@chakra-ui/react';

import { getTypography } from '../utils/typography';

interface ColorsMapping {
  bg: string;
  color: string;
  activeColor: string;
  hoverBorderColor?: string;
}

const _defaultVariantColorsMapping = {
  gray: {
    bg: 'white',
    color: 'text.default',
    activeColor: 'primary.default',
  },
  primary: {
    bg: 'primary.default',
    color: 'white',
    activeColor: 'white',
  },
  success: {
    bg: 'green.default',
    color: 'white',
    activeColor: 'white',
    hoverBorderColor: 'transparent',
  },
  successLight: {
    bg: 'lime.shade',
    color: 'green.default',
    activeColor: 'green.default',
    hoverBorderColor: 'transparent',
  },
  error: {
    bg: 'red.default',
    color: 'white',
    activeColor: 'white',
    hoverBorderColor: 'transparent',
  },
  errorLight: {
    bg: 'red.shade',
    color: 'red.default',
    activeColor: 'red.default',
    hoverBorderColor: 'transparent',
  },
};

const defaultVariantColorsMapping: Record<string, ColorsMapping> =
  _defaultVariantColorsMapping;

declare module '@chakra-ui/react' {
  interface ButtonProps {
    colorScheme?:
      | ThemingProps<'Button'>['colorScheme']
      | keyof typeof _defaultVariantColorsMapping;
  }
}

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'base',
    minH: 'fit-content',
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
  },
  variants: {
    ghost(props) {
      const { colorScheme = 'primary', theme } = props;

      return {
        ...getTypography('body', theme),
        color: `${colorScheme}.default`,
      };
    },
    link(props) {
      return {
        ...getTypography('body', props.theme),
        color: 'black',
      };
    },
    nav(props) {
      return {
        ...getTypography('body', props.theme),
        bg: 'white',
        borderRadius: 'base',
        justifyContent: 'flex-start',
        h: 'unset',
        py: 4,
        px: 6,
        alignItems: 'center',
        border: '1px',
        borderColor: 'grey.shade',
        _hover: {
          bg: 'primary.shade',
          color: 'primary.default',
          borderColor: 'primary.shade',
        },
        '&.active': {
          bg: 'primary.shade',
          color: 'primary.default',
          borderColor: 'primary.default',
        },
      };
    },
    filled(props) {
      const { colorScheme = 'primary', theme } = props;

      return {
        ...getTypography('body', theme),
        bg: `${colorScheme}.shade`,
        color: `${colorScheme}.default`,
        borderRadius: 'base',
        justifyContent: 'flex-start',
        h: 14,
        border: '1px solid',
        borderColor: 'transparent',
        _hover: {
          borderColor: `${colorScheme}.default`,
          _disabled: {
            bg: `${colorScheme}.shade`,
          },
        },
      };
    },
    outline(props) {
      const { colorScheme = 'primary', theme } = props;

      return {
        ...getTypography('body', theme),
        borderColor: `${colorScheme}.default`,
        color: `${colorScheme}.default`,
        _hover: {
          bg: 'background',
        },
      };
    },
    default(props) {
      const { colorScheme, theme } = props;

      const { bg, color, activeColor, hoverBorderColor } =
        defaultVariantColorsMapping[colorScheme] ||
        defaultVariantColorsMapping['gray'];

      return {
        ...getTypography('body', theme),
        bg,
        color,
        border: '1px solid',
        borderColor: 'transparent',
        _active: {
          color: activeColor,
          borderColor: hoverBorderColor ?? 'primary.default',
        },
        _hover: {
          borderColor: hoverBorderColor ?? 'primary.default',
          _disabled: {
            borderColor: 'grey.default',
            bg: 'grey.default',
          },
        },
        _loading: {
          bg,
          _hover: {
            bg,
          },
        },
        _disabled: {
          bg: 'grey.default',
          color: 'white',
          _active: {
            color: 'white',
          },
          '&[data-loading]': {
            opacity: 1,
          },
        },
      };
    },
  },
  sizes: {
    lg: {
      px: 4,
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'default',
    colorScheme: undefined,
  },
};

export default Button;
