import type { ComponentStyleConfig } from '@chakra-ui/theme';

import {
  checkboxAndRadioCommonControl,
  checkboxAndRadioCommonLabel,
} from './common-input';

const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      ...checkboxAndRadioCommonControl,
      borderRadius: '4px',
    },
    label: checkboxAndRadioCommonLabel,
  },
  variants: {
    filled: {
      control: {
        borderColor: 'grey.default',
        rounded: 'base',
        _hover: {
          borderColor: 'primary.dark',
        },
        _checked: {
          color: 'white',
          bg: 'primary.default',
          _hover: {
            bg: 'primary.dark',
          },
        },
        _disabled: {
          borderColor: 'grey.shade',
          bg: 'white',
          _hover: {
            borderColor: 'grey.shade',
            bg: 'white',
          },
        },
      },
    },
  },
  sizes: {
    xl: {
      control: { w: 6, h: 6 },
    },
  },
  defaultProps: {
    size: 'xl',
  },
};

export default Checkbox;
