export const FILE_MAX_UPLOAD_SIZE = 20_000_000; // 20 MB
export const MAX_UPLOAD_FILES_COUNT = 10;
export const INVITATION_TTL_DAYS = 30;
export const APP_LANG_HEADER = 'x-app-lang';
export const APP_RECAPTCHA_HEADER = 'recaptcha';
export const SENDER_ID_MAX_LENGTH = 11;
export const MAX_SAFE_32_SIGNED_INTEGER = 2147483647;
export const RATING_MIN_SCORE = 1;
export const RATING_MAX_SCORE = 10;
export const POLYGONS_MAX_POINTS_COUNT = 1_000;

/** pti stands for Proptly Tracking Identifier */
export const NOTIFICATION_CLICK_TRACKING_IDENTIFIER_PARAM = 'pti';
/** pta stands for Proptly Tracking API */
export const NOTIFICATION_CLICK_TRACKING_API_PARAM = 'pta';

export enum TrackingApi {
  ContractorApi = 'C',
  RoutingApi = 'R',
}

export class FileTypes {
  static imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
    'svg',
    'ico',
  ];
  static audioExtensions = ['mp3', 'wav', 'ogg', 'flac', 'aac'];
  static videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'mkv'];
  static compressedExtensions = ['zip', 'rar', '7z'];
  static documentExtensions = [
    'pdf',
    'doc',
    'docx',
    'csv',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'txt',
  ];
  static get allExtensions() {
    return [
      ...this.imageExtensions,
      ...this.audioExtensions,
      ...this.videoExtensions,
      ...this.compressedExtensions,
      ...this.documentExtensions,
    ];
  }
}
