export default {
  ENVIRONMENT: process.env['NX_PUBLIC_ENVIRONMENT'],
  ADMIN_APP_URL: process.env['NX_PUBLIC_ADMIN_APP_URL'],
  AUTH_API_URL: process.env['NX_PUBLIC_AUTH_API_URL'],
  AUTH_APP_URL: process.env['NX_PUBLIC_AUTH_APP_URL'],
  CHAT_API_URL: process.env['NX_PUBLIC_CHAT_API_URL'],
  CONTRACTOR_API_URL: process.env['NX_PUBLIC_CONTRACTOR_API_URL'],
  CONTRACTOR_CLIENT_APP_URL: process.env['NX_PUBLIC_CONTRACTOR_CLIENT_APP_URL'],
  CONTRACTOR_EMPLOYEE_APP_URL:
    process.env['NX_PUBLIC_CONTRACTOR_EMPLOYEE_APP_URL'],
  CONTRACTOR_GTM_ID: process.env['NX_PUBLIC_CONTRACTOR_GTM_ID'],
  CONTRACTOR_SDK_URL: process.env['NX_PUBLIC_CONTRACTOR_SDK_URL'],
  APP_DOMAIN: process.env['NX_PUBLIC_APP_DOMAIN'],
  FILE_API_URL: process.env['NX_PUBLIC_FILE_API_URL'],
  INTERCOM_API_BASE: process.env['NX_PUBLIC_INTERCOM_API_BASE'],
  INTERCOM_APP_ID: process.env['NX_PUBLIC_INTERCOM_APP_ID'],
  RECAPTCHA_SITE_KEY: process.env['NX_PUBLIC_RECAPTCHA_SITE_KEY'],
  ROUTING_API_URL: process.env['NX_PUBLIC_ROUTING_API_URL'],
  ROUTING_APP_URL: process.env['NX_PUBLIC_ROUTING_APP_URL'],
  ROUTING_GTM_ID: process.env['NX_PUBLIC_ROUTING_GTM_ID'],
  TIMELINE_API_URL: process.env['NX_PUBLIC_TIMELINE_API_URL'],
  MAPBOX_API_KEY: process.env['NX_PUBLIC_MAPBOX_API_KEY'],
  I18N_DEBUGGING: process.env['NX_PUBLIC_I18N_DEBUGGING'],
  MOCK_SERVICE_WORKER_ENABLE:
    process.env['NX_PUBLIC_MOCK_SERVICE_WORKER_ENABLE'],
  API_URL: process.env['NX_PUBLIC_API_URL'],
  METABASE_INSTANCE_URL: process.env['NX_PUBLIC_METABASE_INSTANCE_URL'],
  METABASE_AUTH_PROVIDER_URL:
    process.env['NX_PUBLIC_METABASE_AUTH_PROVIDER_URL'],
};
