import { EUR, NOK, PLN, SEK, USD, GBP } from '@dinero.js/currencies';

export const availableCurrencies = [NOK, PLN, EUR, SEK, USD, GBP];

export const availableCurrencyCodes = availableCurrencies.map(
  (currency) => currency.code,
);

export const availableCurrenciesByCodeMap = new Map<CurrencyCode, Currency>(
  availableCurrencies.map((currency) => [currency.code, currency]),
);

export type Currency = (typeof availableCurrencies)[number];
export type Amount = number;
export type AmountScale = number;
export type CurrencyCode = string;
