const colors = {
  primary: {
    dark: '#0F6A4A', // green dark
    default: '#137856', // green default
    shade: '#E7F5F1', // green shade
    opacity50: '#13785680',
  },
  text: {
    default: '#3A393E', // black
    grey: '#57657A', // grey dark
  },
  black: '#3A393E',
  background: '#F6F8FA',
  green: {
    default: '#137856',
    shade: '#E7F5F1',
    dark: '#0F6A4A',
  },
  red: {
    default: '#F25757',
    shade: '#FFDFDF',
  },
  garnet: {
    default: '#971B40',
    shade: '#F6DBD5',
  },
  mustard: {
    default: '#E8CB64',
    shade: '#F9F6D7',
  },
  spice: {
    default: '#D95100',
    shade: '#FFECE0',
  },
  lime: {
    default: '#81DB28',
    shade: '#EFFBE4',
  },
  avocado: {
    default: '#74BA3C',
    shade: '#E6F2D9',
  },
  cyan: {
    default: '#28AAC3',
    shade: '#DDF4F8',
  },
  peacock: {
    default: '#1B8297',
    shade: '#D5F1F6',
  },
  blue: {
    default: '#3C75BB',
    shade: '#DFF5FF',
  },
  indigo: {
    default: '#1E4BA4',
    shade: '#E7EEF9',
  },
  purple: {
    default: '#6642B3',
    shade: '#ECE1F9',
  },
  amethyst: {
    default: '#7B198A',
    shade: '#F8E5FA',
  },
  magenta: {
    default: '#F578B8',
    shade: '#FEE1F1',
  },
  raspberry: {
    default: '#DD3083',
    shade: '#FAC7D9',
  },
  yellow: {
    default: '#FFAA2A',
    shade: '#FFECD0',
  },
  orange: {
    default: '#FFAA2A',
    shade: '#FFECD0',
  },
  grey: {
    default: '#AEB5BF',
    shade: '#E4EAEF',
    dark: '#57657A',
    light: '#D3D3D3',
    darkShade: '#7E8DA4',
  },
};

export type CustomColorScheme = {
  [P in keyof typeof colors]: (typeof colors)[P] extends {
    default: string;
    shade: string;
  }
    ? P
    : never;
}[keyof typeof colors];

export const rainbowColors = [
  'red',
  'garnet',
  'mustard',
  'yellow',
  'spice',
  'lime',
  'avocado',
  'cyan',
  'peacock',
  'blue',
  'indigo',
  'purple',
  'amethyst',
  'magenta',
  'raspberry',
];

export default colors;
