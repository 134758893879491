import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

export const HorizontalScrollableContainer = forwardRef(
  ({ children, ...props }: FlexProps, ref) => {
    return (
      <Flex
        ref={ref}
        overflowX="auto"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '&': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          '& > *': {
            flexShrink: 0,
          },
        }}
        {...props}
      >
        {children}
      </Flex>
    );
  },
);
