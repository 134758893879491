export enum TimelineEventType {
  OrderCreated = 'ORDER_CREATED',
  OrderSent = 'ORDER_SENT',
  OrderRerouted = 'ORDER_REROUTED',
  OrderPostponed = 'ORDER_POSTPONED',
  OrderUpdated = 'ORDER_UPDATED',
  OrderCancelled = 'ORDER_CANCELLED',
  OrderDone = 'ORDER_DONE',
  OrderAssigneeChanged = 'ORDER_ASSIGNEE_CHANGED',
  OrderRejectedByInvitation = 'ORDER_REJECTED_BY_INVITATION',
  OrderExpiredByInvitation = 'ORDER_EXPIRED_BY_INVITATION',

  /** @deprecated left for showing legacy timeline events */
  EmailOpened = 'EMAIL_OPENED',
  OrderOpened = 'ORDER_OPENED',

  ProjectCreated = 'PROJECT_CREATED',
  OrderReceived = 'ORDER_RECEIVED',
  RatingReceived = 'RATING_RECEIVED',
  RatingRequestSent = 'RATING_REQUEST_SENT',
  RoutingRequestAccepted = 'ROUTING_REQUEST_ACCEPTED',
  RoutingRequestRejected = 'ROUTING_REQUEST_REJECTED',
  ProjectAccepted = 'PROJECT_ACCEPTED',
  ProjectStarted = 'PROJECT_STARTED',
  ProjectCompleted = 'PROJECT_COMPLETED',
  ProjectPaused = 'PROJECT_PAUSED',
  ProjectResumed = 'PROJECT_RESUMED',
  ProjectCancelledByContractor = 'PROJECT_CANCELLED_BY_CONTRACTOR',
  ProjectCancelledByRoutingOperator = 'PROJECT_CANCELLED_BY_ROUTING_OPERATOR',
  ProjectCompletedByRoutingOperator = 'PROJECT_COMPLETED_BY_ROUTING_OPERATOR',
  ProjectAssigneeChanged = 'PROJECT_ASSIGNEE_CHANGED',
  ProjectDatesChanged = 'PROJECT_DATES_CHANGED',

  ProjectMessageCreated = 'PROJECT_MESSAGE_CREATED',

  NoteAdded = 'NOTE_ADDED',
  NoteUpdated = 'NOTE_UPDATED',
  NoteDeleted = 'NOTE_DELETED',

  FileAdded = 'FILE_ADDED',
  FileDeleted = 'FILE_DELETED',

  FilesAdded = 'FILES_ADDED',

  BookingCreated = 'BOOKING_CREATED',
  BookingUpdated = 'BOOKING_UPDATED',
  BookingCancelled = 'BOOKING_CANCELLED',
  BookingRejected = 'BOOKING_REJECTED',
  BookingAccepted = 'BOOKING_ACCEPTED',
  BookingFirstTimeOpened = 'BOOKING_FIRST_TIME_OPENED',

  DecisionCreated = 'DECISION_CREATED',
  DecisionUpdated = 'DECISION_UPDATED',
  DecisionAnswered = 'DECISION_ANSWERED',
  DecisionFirstTimeOpened = 'DECISION_FIRST_TIME_OPENED',

  SaleCreated = 'SALE_CREATED',
  SaleUpdated = 'SALE_UPDATED',
  SaleAccepted = 'SALE_ACCEPTED',
  SaleRejected = 'SALE_REJECTED',
  SaleCancelled = 'SALE_CANCELLED',
  SaleFirstTimeOpened = 'SALE_FIRST_TIME_OPENED',

  ContractCreated = 'CONTRACT_CREATED',
  ContractRejected = 'CONTRACT_REJECTED',
  ContractAccepted = 'CONTRACT_ACCEPTED',
  ContractUpdated = 'CONTRACT_UPDATED',
  ContractCancelled = 'CONTRACT_CANCELLED',
  ContractFirstTimeOpened = 'CONTRACT_FIRST_TIME_OPENED',

  NotificationOnResponseDeadlineApproachingSent = 'NOTIFICATION_ON_RESPONSE_DEADLINE_APPROACHING_SENT',
  NotificationOnResponseDeadlineOverdueSent = 'NOTIFICATION_ON_RESPONSE_DEADLINE_OVERDUE_SENT',
  NotificationOnStartDateApproachingSent = 'NOTIFICATION_ON_START_DATE_APPROACHING_SENT',
  NotificationOnStartDateOverdueSent = 'NOTIFICATION_ON_START_DATE_OVERDUE_SENT',
  NotificationOnEndDateApproachingSent = 'NOTIFICATION_ON_END_DATE_APPROACHING_SENT',
  NotificationOnEndDateOverdueSent = 'NOTIFICATION_ON_END_DATE_OVERDUE_SENT',

  OrderTagAssigned = 'ORDER_TAG_ASSIGNED',
  OrderTagUnassigned = 'ORDER_TAG_UNASSIGNED',
  OrderTagSwapped = 'ORDER_TAG_SWAPPED',
  OrderTagUpdated = 'ORDER_TAG_UPDATED',

  ProjectTagAssigned = 'PROJECT_TAG_ASSIGNED',
  ProjectTagUnassigned = 'PROJECT_TAG_UNASSIGNED',
  ProjectTagSwapped = 'PROJECT_TAG_SWAPPED',
  ProjectTagUpdated = 'PROJECT_TAG_UPDATED',

  OrderNoteCreated = 'ORDER_NOTE_CREATED',
  OrderNoteUpdated = 'ORDER_NOTE_UPDATED',
  OrderNoteDeleted = 'ORDER_NOTE_DELETED',
}

export const routingOnlyEvents = [
  TimelineEventType.OrderCreated, // Hidden in contractor app
  TimelineEventType.OrderSent, // to OrderReceived
  TimelineEventType.OrderDone, // Hidden in contractor app
  TimelineEventType.OrderCancelled, // to Cancelled by RO
  TimelineEventType.OrderRerouted, // to Cancelled by RO
  TimelineEventType.EmailOpened, // Contractor not bound

  TimelineEventType.OrderTagAssigned,
  TimelineEventType.OrderTagUnassigned,
  TimelineEventType.OrderTagSwapped,
  TimelineEventType.OrderTagUpdated,

  TimelineEventType.OrderNoteCreated,
  TimelineEventType.OrderNoteUpdated,
  TimelineEventType.OrderNoteDeleted,
];

export const contractorOnlyEvents = [
  TimelineEventType.ProjectCancelledByRoutingOperator, // from OrderCancelled or OrderRerouted
  TimelineEventType.ProjectCompletedByRoutingOperator, // from OrderDone
  TimelineEventType.OrderReceived, // from OrderSent
  TimelineEventType.ProjectCreated, // Hidden in routing app - for not routed projects

  TimelineEventType.ProjectTagAssigned,
  TimelineEventType.ProjectTagUnassigned,
  TimelineEventType.ProjectTagSwapped,
  TimelineEventType.ProjectTagUpdated,
];
