export type Environment = 'development' | 'staging' | 'production';

export function getEnv(): Environment {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost')) {
    return 'development';
  }

  if (hostname.includes('staging.proptly.no')) {
    return 'staging';
  }

  return 'production';
}
