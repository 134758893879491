import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils/typography';

const Modal: ComponentStyleConfig = {
  baseStyle(props) {
    return {
      header: {
        ...getTypography('bodyLarge', props.theme),
        pb: 3,
        pl: 13,
        borderBottomWidth: '1px',
        borderBottomColor: 'grey.shade',
      },
      body: {
        py: 0,
        px: { mobile: 5, desktop: 10 },
      },
      footer: {
        pb: { mobile: 6, desktop: 4 },
      },
      dialog: {
        mt: props.isCentered ? 'auto' : '112px',
      },
      closeButton: {
        right: 'unset',
        left: 4,
        top: 3,
      },
    };
  },
  sizes: {
    '1500px': {
      dialog: {
        maxW: '1500px',
      },
    },
  },
};

export default Modal;
