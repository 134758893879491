import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';

export interface ImageCircleBoxProps extends BoxProps {
  variant?: 'default' | 'white';
}

const variantStyles: Record<'default' | 'white', BoxProps> = {
  default: {
    bg: 'primary.shade',
  },
  white: {
    bg: 'white',
    border: '1px',
    borderColor: 'grey.shade',
    boxShadow: '0px -5px 5px 0px #0000000A inset',
  },
};

export const ImageCircleBox = forwardRef(
  ({ children, variant = 'default', ...props }: ImageCircleBoxProps, ref) => {
    const theme = variantStyles[variant];

    return (
      <Box
        ref={ref}
        {...theme}
        borderRadius="full"
        w="154px"
        h="154px"
        overflow="hidden"
        display="grid"
        placeItems="center"
        {...props}
      >
        {children}
      </Box>
    );
  },
);

const MotionImageCircleBox = motion(ImageCircleBox);

interface AnimateImageCircleBoxProps
  extends Omit<MotionProps, keyof BoxProps>,
    BoxProps {}

export const AnimateImageCircleBox = ({
  children,
  ...props
}: AnimateImageCircleBoxProps) => {
  return (
    <MotionImageCircleBox
      animate={{ scale: [0.5, 1], opacity: [0, 1] }}
      {...props}
    >
      {children}
    </MotionImageCircleBox>
  );
};
