import { useLayoutEffect } from 'react';

import { Box, Button, Flex, Text, useCallbackRef } from '@chakra-ui/react';
import { ArrowLineDown, File } from '@phosphor-icons/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const PreviewNotSupported = ({
  fileSrc,
  onError,
}: {
  fileSrc: string;
  onError?: (error?: Error) => void;
}) => {
  const [t] = useTranslation();
  const onErrorRef = useCallbackRef(onError);

  const { data } = useSuspenseQuery({
    queryKey: [fileSrc],
    queryFn: () =>
      axios.head(fileSrc, { withCredentials: true }).catch(() => new Error()),
  });

  useLayoutEffect(() => {
    if (data instanceof Error) {
      onErrorRef(new Error());
    }
  }, [data, onErrorRef]);

  return (
    <Flex direction="column" m="auto" alignItems="center">
      <Box
        color="primary.default"
        bg="primary.shade"
        borderRadius="1rem"
        p="12"
        w="fit-content"
        mb="6"
      >
        <File size={100} />
      </Box>

      <Text color="white" align="center">
        {t('couldNotPreviewFile')}
      </Text>
      <Text color="white" align="center" mb="4">
        {t('fileNotSupportedInPreview')}
      </Text>

      <Button
        leftIcon={<ArrowLineDown />}
        as="a"
        size="md"
        colorScheme="primary"
        href={fileSrc}
        download
      >
        {t('download')}
      </Button>
    </Flex>
  );
};
