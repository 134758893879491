import { Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { Trash } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

import { FileIcon } from './file-icon';

export interface FileRowProps {
  onRemove: () => void;
  file: {
    fileName: string;
    src?: string;
  };
  isInvalid?: boolean;
  isDisabled?: boolean;
  onFileClick?: () => void;
}

export const FileRow = ({
  onRemove,
  file,
  isInvalid,
  isDisabled,
  onFileClick,
}: FileRowProps) => {
  const [t] = useTranslation();

  return (
    <Flex
      {...(isInvalid && { color: 'red.default' })}
      align="center"
      w="full"
      gap="4"
      border="1px"
      borderColor="grey.shade"
      borderRadius="base"
      pr="2"
    >
      <FileIcon file={file} />
      <Button
        flex={1}
        minW="0"
        variant="unstyled"
        onClick={() => onFileClick?.()}
        {...(!onFileClick && { cursor: 'default' })}
        textAlign="start"
      >
        <Text
          overflowWrap="anywhere"
          noOfLines={2}
          whiteSpace="normal"
          textStyle="body"
          {...(!isInvalid && { color: 'primary.default' })}
        >
          {file.fileName}
        </Text>
      </Button>

      <IconButton
        isDisabled={isDisabled}
        variant="ghost"
        ml="auto"
        size="sm"
        aria-label={t('removeFile')}
        onClick={onRemove}
        icon={<Trash />}
      />
    </Flex>
  );
};
