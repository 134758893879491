import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import SuperTokens from 'supertokens-auth-react';

export const ns = {
  Errors: 'errors' as const,
  Components: 'components' as const,
  Enums: 'enums' as const,
  Default: 'translation' as const,
};

export type Lang = 'en' | 'nb' | 'sv';

export const languages: Lang[] = ['en', 'nb', 'sv'];
const otherLanguagesFallbacks: Record<string, string[]> = {
  no: ['nb'],
  nn: ['nb'],
};

export const getFallbackLangs = (longCode: string) => {
  const fallbacks = ['en'];
  const [code] = longCode.split('-');

  const otherFallbacks = otherLanguagesFallbacks[code];
  if (otherFallbacks) {
    fallbacks.unshift(...otherFallbacks);
  }

  if (code != 'en' && (languages as string[]).includes(code)) {
    fallbacks.unshift(code);
  }

  return fallbacks;
};

export const changeLanguage = async (lang: string) => {
  await i18n.changeLanguage(lang);
  setTimeout(() => {
    SuperTokens.changeLanguage(lang);
  });
};

interface I18nOptions {
  cacheLang?: boolean;
  order?: string[];
  fallbackNS?: string[];
}

export const initI18n = async ({
  cacheLang,
  order,
  fallbackNS = [],
}: I18nOptions = {}) => {
  await i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      detection: {
        ...(order && { order }),
        ...(cacheLang === false && { caches: [] }),
      },
      backend: {
        loadPath: `locales/{{lng}}/{{ns}}.json`,
      },
      fallbackLng: getFallbackLangs,
      fallbackNS: [...fallbackNS, 'translation'],
      defaultNS: [...fallbackNS, 'translation'],

      debug: process.env['NX_PUBLIC_I18N_DEBUGGING'] === 'true',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

  if (i18n.services.formatter) {
    i18n.services.formatter.add('lowercase', (value) => {
      return value.toLowerCase();
    });

    i18n.services.formatter.add('fallback', (value, lang, options) => {
      if (value != null) {
        return value;
      }

      if (options.to == null) {
        return options.interpolationkey;
      }

      return options.to;
    });
  }

  return i18n;
};
